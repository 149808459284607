<template>
  <v-card
    width="100%"
    flat
  >
    <v-row
      no-gutters
      :style="{ height: '12%' }"
    >
      <v-card-title :style="{ borderBottom: '1px solid #ccc', width: '100%', height: '60px', fontSize: '2.5vh' }">
        {{ title }}
      </v-card-title>
    </v-row>
    <v-row
      no-gutters
      :style="{ height: '88%' }"
      class="pt-2"
    >
      <v-col>
        <v-row no-gutters>
          <v-col
            sm="8"
            md="8"
            lg="10"
            :cols="$vssWidth < 600 ? 4 : 8"
            class="d-flex align-center"
          >
            CEP:
          </v-col>
          <v-col
            sm="4"
            md="4"
            lg="2"
            :cols="$vssWidth < 600 ? 8 : 8"
            class="pt-2"
          >
            <v-text-field
              value=""  
              outlined
              dense
              flat
            />
          </v-col>          
        </v-row>
        <v-row no-gutters>
          <v-col
            sm="5"
            md="5"
            lg="7"
            :cols="$vssWidth < 600 ? 4 : 8"
            class="d-flex align-center"
          >
            Endereço:
          </v-col>          
          <v-col
            sm="7"
            md="7"
            lg="5"
            :cols="$vssWidth < 600 ? 8 : 8"
          >
            <v-text-field
              label=""
              value=""
              outlined
              dense
              flat
              single-line
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            sm="9"
            md="9"
            lg="11"
            :cols="$vssWidth < 600 ? 4 : 8"
            class="d-flex align-center"
          >
            Número:
          </v-col>
          <v-col
            sm="3"
            md="3"
            lg="1"
            :cols="$vssWidth < 600 ? 8 : 8"
            class="pt-2"
          >
            <v-text-field
              outlined
              dense
              flat
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            sm="9"
            md="9"
            lg="10"
            :cols="$vssWidth < 600 ? 4 : 8"
            class="d-flex align-center"
          >
            Complemento:
          </v-col>
          <v-col
            sm="3"
            md="3"
            lg="2"
            :cols="$vssWidth < 600 ? 8 : 8"
          >
            <v-text-field
              label=""
              value=""
              outlined
              dense
              flat
              single-line
              class=""
            />
          </v-col>          
        </v-row>
        <v-row no-gutters>
          <v-col
            sm="9"
            md="9"
            lg="10"
            :cols="$vssWidth < 600 ? 4 : 8"
            class="d-flex align-center"
          >
            Bairro:
          </v-col>
          <v-col
            sm="3"
            md="3"
            lg="2"
            :cols="$vssWidth < 600 ? 8 : 8"
            class="pt-2"
          >
            <v-text-field
              value=""
              outlined
              dense
              flat
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            sm="9"
            md="9"
            lg="10"
            :cols="$vssWidth < 600 ? 4 : 8"
            class="d-flex align-center"
          >
            IBGE:
          </v-col>
          <v-col
            sm="3"
            md="3"
            lg="2"
            :cols="$vssWidth < 600 ? 8 : 8"
            class="pt-2"
          >
            <v-text-field
              value=""
              outlined
              dense
              flat
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            sm="9"
            md="9"
            lg="10"
            :cols="$vssWidth < 600 ? 4 : 8"
            class="d-flex align-center"
          >
            Estado:
          </v-col>
          <v-col
            sm="3"
            md="3"
            lg="2"
            :cols="$vssWidth < 600 ? 8 : 8"
            class="pt-2"
          >
            <v-select
              dense
              outlined
              :items="['PR', 'SC', 'SP']"
              :style="{ width: '100%' }" 
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            sm="9"
            md="9"
            lg="10"
            :cols="$vssWidth < 600 ? 4 : 8"
            class="d-flex align-center"
          >
            Cidade:
          </v-col>
          <v-col
            sm="3"
            md="3"
            lg="2"
            :cols="$vssWidth < 600 ? 8 : 8"
            class="pt-2"
          >
            <v-text-field
              value=""
              outlined
              dense
              flat
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            sm="5"
            md="5"
            lg="9"
            :cols="$vssWidth < 600 ? 4 : 8"
            class="d-flex align-center"
          >
            E-mail:
          </v-col>
          <v-col
            sm="7"
            md="7"
            lg="3"
            :cols="$vssWidth < 600 ? 8 : 8"
            class="pt-2"
          >
            <v-text-field
              value=""
              outlined
              dense
              flat
            />
          </v-col>
        </v-row>  
        <v-row no-gutters>
          <v-col
            sm="5"
            md="5"
            lg="9"
            :cols="$vssWidth < 600 ? 4 : 8"
            class="d-flex align-center"
          >
            Site:
          </v-col>
          <v-col
            sm="7"
            md="7"
            lg="3"
            :cols="$vssWidth < 600 ? 8 : 8"
            class="pt-2"
          >
            <v-text-field
              value=""
              outlined
              dense
              flat
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            sm="7"
            md="7"
            lg="9"
            :cols="$vssWidth < 600 ? 4 : 8"
            class="d-flex align-center"
          >
            Telefone:
          </v-col>
          <v-col 
            sm="1"
            md="1"
            lg="1"
            :cols="$vssWidth < 600 ? 2 : 8"
          >
            <v-text-field
              label=""
              value=""             
              outlined
              dense
              flat        
              single-line   
              class="" 
            />
          </v-col>
          <v-col 
            sm="4"
            md="4"
            lg="2"
            :cols="$vssWidth < 600 ? 6 : 8"
            class="pl-2"
          >
            <v-text-field
              label=""
              value=""
              outlined
              dense
              flat        
              single-line
            />
          </v-col>
        </v-row>  
        <v-row no-gutters>
          <v-col
            sm="7"
            md="7"
            lg="9"
            :cols="$vssWidth < 600 ? 4 : 8"
            class="d-flex align-center"
          >
            Celular:
          </v-col>
          <v-col 
            sm="1"
            md="1"
            lg="1"
            :cols="$vssWidth < 600 ? 2 : 8"
          >
            <v-text-field
              label=""
              value=""
              outlined
              dense
              flat        
              single-line   
              class="" 
            />
          </v-col>
          <v-col 
            sm="4"
            md="4"
            lg="2"
            :cols="$vssWidth < 600 ? 6 : 8"
            class="pl-2"
          >
            <v-text-field
              label=""
              value=""
              outlined
              dense
              flat        
              single-line
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      

    }
  },
  watch: {

  },
  mounted() {

  },
  methods: {
    
  },

}
</script>